<template>
  <!-- 审核魅力东疆 -->
  <div>
    <a-button @click="openUpload" style="margin-left: 10px;" type="primary">上传照片至魅力东疆</a-button>

    <!-- 航拍的表格 -->
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      :row-key="(record) => record.id"
      @change="changeMessageData"
    >
      <template #mypicture="{ text }">
          <img :src="text" alt="图片"  height="40"/>
        </template>
      <template #name="{ text }">
        <a>{{ text }}</a>
      </template>
      <template #action="{ text }">
        <a-button type="primary" class="delete" @click="showDetails(text)"
          >查看详情</a-button
        >
        <a-button
          type="primary"
          class="delete"
          @click="examineCharmS(text)"
          v-show="text.type === 0 && roleId === 0"
        >
          通过
        </a-button>
        <a-button
          type="danger"
          class="delete"
          @click="examineCharmF(text)"
          v-show="text.type === 0 && roleId === 0"
        >
          不通过
        </a-button>
        <a-button
          type="danger"
          class="delete"
          @click="deleteCharm(text)"
          v-show="roleId == 0"
          >删除</a-button
        >
      </template>
    </a-table>
    <a-modal
      title="详细信息"
      v-model:visible="visible"
      :footer="null"
      width="900px"
    >
      <div style="height:200px; position:relative">
        <div style="width:50%; position:absolute" >
        <ul class="input_list" >
        <li>
          <span>提交人员： {{ eastmessage.user }} </span>
        </li>
        <li>
          <span>提交时间： {{ eastmessage.time }} </span>
        </li>
        <li>
          <span>内容描述： {{ eastmessage.message }} </span>
        </li>
        <li v-show="showFlyRegion">
          <span>拍摄区域： {{ eastmessage.regionId }} </span>
        </li>
        <li>
          <span>图片展示：</span>
        </li>
      </ul>
     </div>
      <div style="width:48%;height:200px;right: 1%; position:absolute">
        <a-button type="danger" style="margin-left: 70%"  @click="deleteCharm(eastmessage)"
          v-show="roleId == 0" >删除</a-button>
      </div>
      </div>

      <div style="height: 400px;position: relative">
        <img :src="photoUrl"  style="height: 400px; margin-bottom: 20px; position: absolute;left:50%;transform: translateX(-50%) ">
      </div>
    </a-modal>
  </div>

  <a-modal
      v-model:visible="uploadVisible"
      title="上传街拍图片"
      @cancel="cancelUpload"
      @ok="uploadCleanerPhotos"
      ok-text="确认"
      cancel-text="取消"
  >
    <input
      type="file"
      ref="fileRef"
      @change="choosePhoto"
      style="display: none"
    />
    <a-button @click="comeToChoose">选择图片</a-button>
    <img src="" ref="photoRef" style="width: 90%; margin: 10px 0;">
    <a-textarea v-model:value="photoText" placeholder="请输入文字介绍" :rows="4" style="width: 90%; margin: 10px 0;"/>
  </a-modal>
</template>

<script>
import { message } from "ant-design-vue";
import { defineComponent } from "vue";
import OSS from "ali-oss";

// 航拍的表格
const columns = [
  {
    title: "图片",
    dataIndex: "simpleUrl",
    key: "simpleUrl",
    align: "center",
    slots: { customRender: "mypicture" },
  },
  {
    title: "提交人员",
    dataIndex: "userName",
    key: "userName",
    align: "center",
  },
  {
    title: "提交时间",
    dataIndex: "time",
    key: "time",
    align: "center",
  },
  {
    title: "拍摄区域",
    dataIndex: "regionId",
    key: "regionId",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "typeName",
    key: "typeName",
    align: "center",
  },
  {
    title: "操作",
    slots: { customRender: "action" },
    key: "action",
    align: "center",
  },
];


const data = [];

const client = new OSS({
  region: "oss-cn-beijing",
  accessKeyId: "LTAI5tAhC8qbUaiTHPh7dEuA",
  accessKeySecret: "TfALWBoft5i0A2P11Vxd1DGqBqj9bE",
  bucket: "rubbish-demo",
});

export default defineComponent({
  components: {
    // LeftOutlined,
    // RightOutlined,
  },
  data() {
    return {
      // 浏览魅力东疆展示的是上传时间还是拍摄时间
      showUpdateTime: true,
      //点击查看详情后是否显示拍摄区域
      showFlyRegion: true,
      //显示的街拍图片的地址
      showUrl:'',
      showUrl0: '',
      // 针对管理员或者普通用户进行页面管理
      isManager: true,
      data: data,
      columns: columns,
      loading: false,
      visible: false,
      visibleStreet: false,
      eastmessage: {
        id:'',
        user: "",
        time: "",
        message: "",
        regionName: "",
        photoUrl: "",
        photoId: "",
        modelType: 0,
      },
      pagination: {
        //分页相关
        current: 1,
        pageSize: 8,
        total: 10,
      },
      photoUrl: '',
      //权限相关
      roleId: this.$storage.get("userinfo").data.role,
      isNextShow: true,
      isPreShow: false,

      //新需求相关
      uploadVisible: false,//上传图片的模态框是否显示
      photoObj: null,//用于保存待上传图片
      photoText: "",//用于文字介绍
    };
  },
  computed: {
  },
  methods: {
    //页面构建的时候获取列表信息
    getFlyTable() {
      this.loading = true;
      let url = "http://39.100.158.75:8080/showPhoto/getAllShowPhotoByPage";
      this.$axios({
        url: url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          limit: this.pagination.pageSize,
          page: this.pagination.current,
        },
      }).then((res) => {
        this.loading = false;
        this.data = [];
        if (res.data.data == null) {
          this.pagination.total = 0;
          this.pagination.current = 1;
        } else {
          for (const el of res.data.data.list) {
            if (el.type == 0) {
              var typeName = "等待审核";
            } else if (el.type == 1) {
              typeName = "审核通过";
            } else {
              typeName = "审核未通过";
            }
            var simpleUrl = el.photo.photoUrl;
            console.log(simpleUrl);
              this.data.push({
              id: el.id,
              type: el.type,
              typeName: typeName,
              userName: el.operator.userName,
              time: el.time,
              regionId: el.regionId,
              message: el.message,
              photoUrl: el.photo.photoUrl,
              photoId: el.photo.id,
              modelType: el.modelType,
              simpleUrl: simpleUrl,
            });
          }
          this.pagination.total = res.data.data.totalPageCount;
        }
      });
    },
    changeMessageData(event) {
      this.pagination.current = event.current;
      this.getFlyTable();
    },
    //航拍系列操作
    //审核图片
      //通过
    examineCharmS(text) {
      if(text.modelType === 0) {
        let url = "http://39.100.158.75:8080/showPhoto/updateOneShowPhoto";
      this.$axios({
        url: url,
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        method: "POST",
        params: {
          id: text.id,
          type: 1,
        },
      }).then((res) => {
        message.info(res.data.message);
        this.getFlyTable();
      });
      } else {
        let url = "http://39.100.158.75:8080/showPhoto/updateOneShowPhotoCleaner";
      this.$axios({
        url: url,
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        method: "POST",
        params: {
          id: text.id,
          type: 1,
        },
      }).then((res) => {
        message.info(res.data.message);
        this.getFlyTable();
      });
      }
      
    },
      // 不通过
    examineCharmF(text) {
      if(text.modelType === 0) {
        let url = "http://39.100.158.75:8080/showPhoto/updateOneShowPhoto";
        this.$axios({
        url: url,
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        method: "POST",
        params: {
          id: text.id,
          type: 2,
        },
      }).then((res) => {
        message.info(res.data.message);
        this.getFlyTable();
      });
      } else {
        let url = "http://39.100.158.75:8080/showPhoto/updateOneShowPhotoCleaner";
      this.$axios({
        url,
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        method: "POST",
        params: {
          id: text.id,
          type: 2,
        },
      }).then((res) => {
        message.info(res.data.message);
        console.log(res);
        this.getFlyTable();
      });
      }
    },
    onOk() {
      this.visible = false;
    },
    //删除审核信息
    deleteCharm(text) {
      console.log(text);
      if(text.modelType === 0) {
        let url = "http://39.100.158.75:8080/showPhoto/deleteOneShowPhoto";
        this.$axios({
        url: url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          id: text.id,
        },
      }).then((res) => {
        message.info(res.data.message);
        this.visible = false;
        this.getFlyTable();
      });
      } else {
        let url = "http://39.100.158.75:8080/showPhoto/deleteOneShowPhotoCleaner";
      this.$axios({
        url: url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          id: text.id,
        },
      }).then((res) => {
        message.info(res.data.message);
        this.visible = false;
        this.getFlyTable();
      });
      }
    },
    //点击查看详情
    showDetails(text) {
      console.log(text);
      if(text.modelType === 1) {
        this.showFlyRegion = false;
        this.eastmessage.modelType = 1;
      } else {
        this.showFlyRegion = true;
        this.eastmessage.modelType = 0;
      }
      this.eastmessage.user = text.userName;
      this.eastmessage.time = text.time;
      this.eastmessage.message = text.message;
      this.eastmessage.regionId = text.regionId;
      this.eastmessage.photoUrl = text.photoUrl;
      this.eastmessage.photoId = text.photoId;
      this.eastmessage.id = text.id;
      this.photoUrl = text.photoUrl
      this.visible = true;
    },
    //模态框的取消按钮
    handleCancel() {
      this.visible = false;
    },
    //打开模态框
    openUpload() {
      this.uploadVisible = true;
    },
    //跳转点击上传图片
    comeToChoose() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
    },
    //选择上传的图片
    choosePhoto(event) {
      this.photoObj = event.target.files[0];
      event.target.value = '';
      if (this.photoObj.type === "image/jpeg" || this.photoObj.type === "image/jpeg" || this.photoObj.type === "image/jpeg") {
        let photoUrl = URL.createObjectURL(this.photoObj);//生成上传图片的URL
        this.$refs.photoRef.src = photoUrl;
      } else {
        message.error("文件" + this.photoObj.name + "格式错误，请重新上传！");
      }
    },
    //上传垃圾清理人员的图片至魅力东疆
    async uploadCleanerPhotos() {
      this.uploadVisible = false;
      this.$refs.photoRef.src = "";
      //OSS上传
      let nowTime = new Date().getTime();
      if(this.photoObj == null) this.photoText = '';
      let fileNme = nowTime + "." + this.photoObj.name;//文件名
      let result = await client.put(fileNme, this.photoObj);
      let ossUrl = result.url;
      console.log(ossUrl);
      this.$axios({
        url: "http://39.100.158.75:8080/showPhoto/insertOneShowPhotoCleaner",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get('userinfo').data.token
        },
        data:
          {
            operatorId: this.$storage.get('userinfo').data.userId,
            photoUrl: ossUrl,
            comment: this.photoText,
            type: '0'
          }
      })
      .then((response) => {
        this.photoText = '';
        console.log(response);
        if(response.data.success === true) {
          this.$message.success(response.data.message);
          this.getFlyTable();
        }
        else {
          this.$message.error(response.data.message);
        }
      }).catch(() => {
        this.photoText = '';
      })
      
    },
    //取消上传
    cancelUpload() {
      this.uploadVisible = false;
      message.info("您已取消上传");
      this.$refs.photoRef.src = "";
      this.photoText = '';
    }
  },
  mounted() {
    this.getFlyTable();
  },
  created() {
    if(this.$storage.get('userinfo').data.role !== 0) {
      this.isCheck = false;
      this.isManager = false;
    }
  },

});
</script>

<style lang="scss" scoped>
:deep().ant-table-tbody > tr > td {
  padding: 10px;
}
.delete {
  margin-left: 10px;
}
.input_list {
  padding: 0px;
  list-style: none;
  li {
    line-height: 44px;
    list-style: none;
  }
}
.pic-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20;
}
.delete-box {
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(530px, -325px);
  .a-button {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
}
#feature-pic {
  position: absolute;
  width: 1080px;
  height: 607.5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
}
.head {
  width: 100%;
  height: 50px;
  position: relative;
  .carousel-box {
    width: 800px;
    height: 460px;
    position: absolute;
    top: -36px;
    left: 50%;
    transform: translateX(-50%);
    .carousel-pic {
      // border: 1px solid #ccc;
      position: relative;
      width: 100%;
      #human_lable {
        width: 100%;
        height: 490px;
        position: relative;
        cursor: pointer;
      }
      .carousel-btn {
        font-size: 40px;
        height: 45px;
        width: 45px;
        z-index: 200;
      }
      .right-button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
      }
      .left-button {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }
  }
  .message-box {
    position: absolute;
    padding: 1%;
    left: 50%;
    top: 460px;
    transform: translate(-50%, 0);
    width: 800px;
    border-radius: 2px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18), 0 0 6px rgba(0, 0, 0, 0.13);
  }
}
.btn {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 92%;
}
</style>
